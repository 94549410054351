import React, { useRef, useState, useEffect } from "react";
import { Stage, Layer, Image, Rect } from "react-konva";
import apiService from "../../service";
import { useParams } from "react-router-dom";
import CustomBreadCrumbs from "../BreadCrumb/BreadCrumb";
import NavigationComponent from "../NavigationComponent/NavigationComponent";
import '../PreviewComponent/PreviewComponent.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const colors = [
  "red",
  "blue",
  "green",
  "yellow",
  "purple",
  "orange",
  "pink",
  "cyan",
  "magenta",
];


const useImage = (src) => {
    const [image, setImage] = useState(null);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      if (src) {
        const img = new window.Image();
        img.src = src;
        img.onload = () => setImage(img);
        img.onerror = () => setError("Failed to load image");
      }
    }, [src]);
  
    return [image, error];
  };

const DataLabels = {
  "(I.R.S. ID)": "I.R.S ID",
  "(STATE": "State",
  "Accelerated filer": "Accelerated filer",
  "Commission File Number": "Commission File Number",
  "Emerging growth company": "Emerging growth company",
  "For the Quarterly Period Ended": "For the Quarterly Period Ended",
  "Large accelerated filer": "Large accelerated filer",
  "Non-accelerated filer": "Non-accelerated filer",
  "No": "No",
  "Yes": "Yes",
  "QUARTERLY REPORT PURSUANT TO SECTION 13 OR 15(d) OF THE SECURITIES EXCHANGE ACT OF 1934": "Quarterly Report Pursuant to Section 13 or 15(d) of the Securities Exchange Act of 1934",
  "Securities registered pursuant to Section 12(g) of the Act:": "Securities registered pursuant to Section 12(g) of the Act",
  "TRANSITION REPORT PURSUANT TO SECTION 13 OR 15(d) OF THE SECURITIES EXCHANGE ACT OF 1934": "Transition Report Pursuant to Section 13 or 15(d) of the Securities Exchange Act of 1934",
  "If an emerging growth company, indicate by check mark if the registrant has elected not to use the extended transition period for complying with any new or revised financial accounting standards provided pursuant to Section 13(a) of the Exchange Act.": "If an emerging growth company, indicate by check mark if the registrant has elected not to use the extended transition period for complying with any new or revised financial accounting standards provided pursuant to Section 13(a) of the Exchange Act.",
  "Date Issued:": "Date Issued",
  "Telephone us:": "Telephone us",
  "Police Precept": "Police Precept",
  "Telephone us :-": "Telephone us",
  "Reason for Bill:": "Reason for Bill",
  "Account Reference:": "Account Reference",
  "Property Reference:": "Property Reference",
  "Net Liability for Year": "Net Liability for Year",
  "Adult Social Care Precept": "Adult Social Care Precept",
  "Great Yarmouth Borough Council": "Great Yarmouth Borough Council",
  "Council Tax for period 01/04/24 to 31/03/25": "Council Tax for period 01/04/24 to 31/03/25",
  "Amount Payable by you for this financial year": "Amount Payable by you for this financial year",
  "Address and Band of property giving rise to charge": "Address and Band of property giving rise to charge",
  "Norfolk County Council Non-Adult Social Care charge": "Norfolk County Council Non-Adult Social Care charge"
}


const GenDocumentPreview = () => {
  const [rectangles, setRectangles] = useState([]);
  const [hoveredRectIndex, setHoveredRectIndex] = useState(null);
  const [imageBlobUrls, setImageBlobUrls] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0); // Use a single index for image and page
  const [image, error] = useImage(imageBlobUrls[currentIndex]);
  const stageRef = useRef(null);
  const [breadCrumb, setBreadCrumb] = useState([])
  const [fileName, setFileName] = useState('');
  const [maxIndex, setMaxIndex] = useState()
  const panelRef = useRef(null);
  const { id } = useParams();
  const [dataAvailable, setDataAvailable] = useState(null);
  const stageWidth = 830;
  const stageHeight = image
    ? (image.height * stageWidth) / image.width
    : window.innerHeight;


  const convertToPixels = (polygon, scaleFactor) => {
    return polygon.map((coord) => coord * scaleFactor);
  };

  const calculateBoundingBox = (polygon) => {
    const xCoords = polygon.filter((_, index) => index % 2 === 0);
    const yCoords = polygon.filter((_, index) => index % 2 !== 0);

    const xMin = Math.min(...xCoords);
    const xMax = Math.max(...xCoords);
    const yMin = Math.min(...yCoords);
    const yMax = Math.max(...yCoords);

    const width = xMax - xMin;
    const height = yMax - yMin;
    return {
      x: xMin,
      y: yMin,
      width,
      height,
    };
  };

  

  useEffect(() => {
    if (dataAvailable && dataAvailable.extracted_data) {
      const scaleFactor = 100; // Example scale factor
      const data = [];
      console.log(dataAvailable);
      setFileName(dataAvailable.file_name)
      const breadCrumbArray = [
        {
          name: "General Document",
          color: "#000000",
          underline: "none",
          href: "/doc-ai/general-document",
        },
        {
          name: dataAvailable.file_name,
          color: "#767676",
          underline: "none",
        }
      ];
      setBreadCrumb(breadCrumbArray)
      setMaxIndex(dataAvailable.source_images.length)
      for (const key in dataAvailable.extracted_data) {
        if (dataAvailable.extracted_data.hasOwnProperty(key)) {
          const boundary = dataAvailable.extracted_data[key];
          if (boundary.Boundary.length > 0) {
            console.log(currentIndex);
            if (
              boundary.Boundary[0].PageNumber === currentIndex + 1 &&
              boundary.Boundary &&
              boundary.Boundary.length > 0
            ) {
              console.log(boundary.Boundary);
              const polygon = Array.isArray(boundary.Boundary[0].Polygon[0])
              ? boundary.Boundary[0].Polygon.flat()
              : boundary.Boundary[0].Polygon;

              const boundingBoxPDF = calculateBoundingBox(polygon);
              const boundingBoxInPixels = convertToPixels(
                [
                  boundingBoxPDF.x,
                  boundingBoxPDF.y,
                  boundingBoxPDF.width,
                  boundingBoxPDF.height,
                ],
                scaleFactor
              );

              const increasedWidth = boundingBoxInPixels[2] + 25;
              const increasedHeight = boundingBoxInPixels[3] + 5;

              if (boundary['Type']) {
                data.push({
                  [boundary['Type']]: boundary.ValueString || boundary.Content,
                  x: boundingBoxInPixels[0],
                  y: boundingBoxInPixels[1],
                  width: increasedWidth,
                  height: increasedHeight,
                  color: colors[data.length % colors.length],
                });
              }
            }
          }
        }
      }


      setRectangles(data);
      console.log(dataAvailable.source_images);
      setImageBlobUrls(dataAvailable.source_images || []);
    }
  }, [dataAvailable, currentIndex]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await apiService.getGenDocumentDataById(id);
        const data = response;
        setDataAvailable(data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [id]);

  const handleMouseEnterRect = (index) => {
    setHoveredRectIndex(index);
  };

  const handleMouseLeaveRect = () => {
    setHoveredRectIndex(null);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, maxIndex - 1));
  };
  return (
    <>
      <CustomBreadCrumbs array={breadCrumb} />
      <div className="general-doc-validation">

        <div className="konva-container">
          <div className="konva-scroll">
          {imageBlobUrls.length > 1 && (
          <div style={{display : "flex",flexDirection : 'column', padding :'0px 20px', justifyContent :'center', alignItems :'center'}}>
            
           <NavigationComponent
          onPrevious={handlePrevious}
          onNext={handleNext}
          isPreviousDisabled={currentIndex === 0}
          isNextDisabled={currentIndex === maxIndex - 1}
        />
         <p style={{margin :0}}>{currentIndex + 1} of {maxIndex}</p>
       
          </div>
  
            )}
            <div className="konva-scroll-inner">
              <Stage
                className="konva-stage"
                width={stageWidth}
                height={stageHeight}
                ref={stageRef}
              >
                <Layer>
                  {image && (
                    <Image
                      image={image}
                      width={stageWidth}
                      height={stageHeight}
                    />
                  )}
                  {rectangles.map((rect, index) => (
                    <Rect
                      key={index}
                      x={rect.x}
                      y={rect.y}
                      width={rect.width}
                      height={rect.height}
                      fill=""
                      stroke={hoveredRectIndex === index ? "red" : rect.color}
                      strokeWidth={2}
                      draggable
                      onMouseEnter={() => handleMouseEnterRect(index)}
                      onMouseLeave={handleMouseLeaveRect}
                      onDragEnd={(e) => {
                        const newRectangles = [...rectangles];
                        newRectangles[index] = {
                          ...rect,
                          x: e.target.x(),
                          y: e.target.y(),
                        };
                        setRectangles(newRectangles);
                      }}
                      onTransformEnd={(e) => {
                        const node = e.target;
                        const scaleX = node.scaleX();
                        const scaleY = node.scaleY();

                        node.scaleX(1);
                        node.scaleY(1);
                        const newRectangles = [...rectangles];
                        newRectangles[index] = {
                          ...rect,
                          x: node.x(),
                          y: node.y(),
                          width: Math.max(5, node.width() * scaleX),
                          height: Math.max(5, node.height() * scaleY),
                        };
                        setRectangles(newRectangles);
                      }}
                    />
                  ))}
                </Layer>
              </Stage>
            </div>
          </div>
        </div>
        <div className="panel" ref={panelRef}>
          <div className="container">
            {rectangles.map((item, index) => {
              const [key, value] = Object.entries(item).find(
                ([k]) =>
                  k !== "x" &&
                  k !== "y" &&
                  k !== "width" &&
                  k !== "height" &&
                  k !== "color"
              );
              return (
                <div
                  key={index}
                  className={`data-item ${hoveredRectIndex === index ? "highlight" : ""
                    }`}
                >
                  <div className="label">{key}</div>
                  <div className="value">
                    {key === "Blob URL" ? (
                      <a href={value} target="_blank" rel="noopener noreferrer">
                        View Document
                      </a>
                    ) : (
                      value
                    )}
                  </div>
                </div>
              );
            })}
            <div>
              {/* {dataAvailable?.blob_url.length > 0  && <ManualCarousel images={dataAvailable?.blob_url} / >} */}
            </div>
          </div>
        </div>

        {error && <p>{error}</p>}
      </div>
    </>
  );
}

export default GenDocumentPreview;